import React from 'react';

function PalettePreview ({ palette }) {
  return (
    <div className="theme shadow">
      <div className="theme__status" style={{ background: palette.darkPrimary }}>
      </div>
      <div className="theme__toolbar" style={{ background: palette.defaultPrimary }}>
        <div className="theme__toolbar-navigation">
          <i className="material-icons more">more_vert</i>
          <i className="material-icons back">arrow_back</i>
        </div>
        <div className="theme__toolbar-headings">
          <h1>Palette preview</h1>
          <h2 style={{ color: palette.lightPrimary }}>Full Palette colors below</h2>
        </div>
        <div className="theme__button" style={{ background: palette.accentPrimary }}>
          <i className="material-icons">grade</i>
        </div>
      </div>
      <div className="theme__body">
        <ul>
          <li className="clearfix">
            <i className="material-icons">label</i>
            <p>
              <a href="https://www.uplabs.com/">Daily Design Showcase</a>
              <br />
              <em><a href="https://www.uplabs.com/">Visit Uplabs</a></em>
            </p>
          </li>
          <li className="clearfix">
          <i className="material-icons">schedule</i>
            <p>
              <a href="http://uplabs.com/">Daily Resources for Designers &amp; Developers</a>
              <br />
              <em><a href="https://www.uplabs.com/">Visit UpLabs</a></em>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PalettePreview;
