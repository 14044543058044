import React from 'react';
import classnames from 'classnames';
import CarbonAd from 'components/CarbonAd';

function Color({color, showLabel, onClick, animationDelay}) {
  const colotWrapperClass = classnames('palette-color-wrapper', {'palette-color-wrapper--double': showLabel });
  return (
    <div className={colotWrapperClass}>
      { showLabel && <div className="palette-color palette-color--ad animated fadeIn">
        <CarbonAd />
      </div>
      }
      <div
        className={classnames(`palette-color palette-color--color animated fadeIn palette-color--color-${color.key}`, {selected: color.selected})}
        onClick={e => onClick(color)}
        style={{ animationDelay }}
      >
        <div style={{ background: color.shades[500]['hex'] }}>
          <i className="material-icons">check</i>
          <label
            className={`palette-color-label palette-color-label--${color.shades[500]['contrast']}`}
          >{color.name}</label>
        </div>
      </div>
    </div>
  );
};

export default Color;
