import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PaletteDetails from 'components/PaletteDetails';
import PalettePreview from 'components/PalettePreview';
import Color from 'components/Color';
import $ from 'jquery';
require('jquery.smooth-scroll/jquery.smooth-scroll')


export default class Palette extends Component {
  static propTypes = {
    colors:       PropTypes.array.isRequired,
    primaryColor: PropTypes.object,
    accentColor:  PropTypes.object,
    rootUrl:      PropTypes.string.isRequired
  }

  static defaultProps = {
    primaryColor: null,
    accentColor:  null
  }

  constructor(props) {
    super(props);
    this.state = {
      showPalette:  false,
      primaryColor: props.primaryColor,
      accentColor:  props.accentColor
    };
    this.selectedCount = 0;

    if (props.primaryColor != null) {
      this._selectColorByKey(props.primaryColor.key);
      this._selectColorByKey(props.accentColor.key);
      this.state.showPalette = true;
    }
  }

  componentDidMount() {
    $('.palette').height(($(window).height() - 60));
    $(window).resize(function() {
      $('.theme-wrapper').height(($(window).height() - 60));
      $('.palette').height(($(window).height() - 60));
    });
  }

  _selectColorByKey(key) {
    this.props.colors.forEach(color => {
      if (color.key === key) {
        color.selected = true;
        this.selectedCount++;
      }
    });
  }

  _paletteColors() {
    const { primaryColor, accentColor } = this.state;
    if (primaryColor === null || accentColor === null) {
      return {};
    }

    const primaryContrast = primaryColor.shades[500].contrast;
    const accentPrimary = (
      (accentColor.shades['A200'] && accentColor.shades['A200'].contrast === 'white') ?
      accentColor.shades['A200'].hex :
      accentColor.shades[500].hex
    );

    return {
      primary:                primaryColor,
      accent:                 accentColor,
      darkPrimary:            primaryColor.shades[700].hex,
      defaultPrimary:         primaryColor.shades[500].hex,
      lightPrimary:           primaryColor.shades[100].hex,
      accentPrimary:          accentPrimary,
      defaultPrimaryContrast: primaryContrast,
      lightPrimaryContrast:   primaryColor.shades[100].contrast,
      darkPrimaryContrast:    primaryColor.shades[700].contrast,
      accentPrimaryContrast:  accentColor.shades[500].contrast,
      textPrimary:            (primaryContrast === 'white' ? '#FFFFFF' : '#212121'),
      textPrimaryContrast:    (primaryContrast === 'white' ? 'black' : 'white')
    }
  }

  _toggleColor = (color) => {
    if (this.selectedCount === 2) {
      this.resetSelected();
    }

    if (color.selected === true) {
      color.selected = false;
      this.selectedCount--;
    } else {
      color.selected = true;
      this.selectedCount++;
    }

    if (this.selectedCount === 2) {
      this.state.accentColor = color;
      this.state.showPalette = true;
      if (window.innerWidth < 768) {
        setTimeout(() =>
          $.smoothScroll({
            easing:         'swing',
            offset:         0,
            scrollTarget:   '.theme-wrapper',
            speed:          500,
            autoCoefficent: 2
          }), 200);
      }
    } else {
      this.state.primaryColor = color;
    }

    if (history.pushState && this.state.primaryColor != null && this.state.accentColor != null) {
      window.history.pushState('', '', `/${this.state.primaryColor.key}/${this.state.accentColor.key}`);
    }
    this.forceUpdate();
  }

  resetSelected() {
    this.props.colors.forEach(color => color.selected = false);
    this.selectedCount = 0;
  }

  render() {
    const paletteWrapClass = classnames('palette clearfix', { 'palette--small': this.state.showPalette });
    return (
      <div className="palette-wrapper">
        <div className={paletteWrapClass}>
          { this.props.colors.map((color, index) =>
            <Color
              key={index}
              color={color}
              showLabel={index === 2}
              onClick={this._toggleColor}
              animationDelay={`${25 * index}ms`}
            />)
          }
        </div>
        { this.state.showPalette &&
          <div className="theme-wrapper animated fadeInRightBig">
            <PalettePreview palette={this._paletteColors()} />
            <PaletteDetails palette={this._paletteColors()} rootUrl={this.props.rootUrl} />
          </div>
        }
      </div>
    );
  }
}
